/* ViewCard.css */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Fondo semi-transparente negro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegurarse de que el modal esté por encima de otros elementos */
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.card-details-container {
    flex: 1; /* Ocupa el espacio restante */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Centrar el contenido horizontalmente */
    margin-left: 20px;
}


.card-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    background: none;
    border: none;
    color: #333;
}

.img-carta-modal {
    width: 50%;
    max-width: 300px !important;
    height: auto;
    margin-right: 20px;
}

.card-info {
    flex: 1;
}

.card-info h2 {
    margin-top: 0;
}

.viewcard-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 25%;
}


.viewcard-actions button {
    color: black; /* Color de texto por defecto */
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 40px; /* Establecer un ancho para los botones */
    height: 40px; /* Establecer un alto para los botones */
    display: flex; /* Centrar el ícono en el botón */
    justify-content: center;
    align-items: center;
}

.viewcard-actions button:hover {
    background-color: #ddd;
}

.viewcard-add-btn {
    background-color: #4CAF50; /* Verde */
    color: white;
}

.viewcard-remove-btn {
    background-color: #f44336; /* Rojo */
    color: white;
}

.viewcard-remove-btn:hover {
    color:#f44336;
}

.viewcard-trade-btn {
    background-color: #2196F3; /* Azul */
    color: white;
}

.viewcard-trade-btn:hover {
    color:#2196F3;
}

.viewcard-search-btn {
    background-color: #ff9800; /* Naranja */
    color: white;
}

.viewcard-search-btn:hover {
    color: #ff9800;
}
