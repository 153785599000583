.search-results-table {
    width: 100%;
    border-collapse: collapse;
}

.search-results-table th, .search-results-table td {
    border: 1px solid #ccc;
    padding: 8px 12px;
    text-align: center;
}

.search-results-table th {
    background-color: #f5f5f5;
}

.search-results-table img {
    max-width: 80px;
    height: auto;
}

.search-container{
    align-items:end;
}

.btn-green {
    background-color: rgb(64, 113, 64) !important;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.btn-red {
    background-color: rgb(252, 52, 52) !important;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.btn-green:hover {
    background-color: #013e01; /* Un verde más oscuro */
}

.btn-red:hover {
    background-color: #8B0000; /* Un rojo más oscuro */
}

.btn-green, .btn-red {
    transition: background-color 0.3s ease;
}
