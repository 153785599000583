.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Ajusté el tamaño de las cartas */
    gap: 10px;
    padding: 0px 10px;
}

.card-item {
    position: relative;
    overflow: hidden;
    border-radius: 5%;
}

.card-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s;
}

.card-item:hover img {
    transform: scale(1.1);
    filter: grayscale(1%);
}

.card-bw {
    filter: grayscale(90%);
}

.card-label.missing-card {
    background-color: rgba(128, 128, 128, 0.85); /* Color gris con 85% de opacidad */
}

.card-label {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 93%;
    /*background-color: rgba(0, 0, 0, 0.6); /* Color de fondo negro con 60% de opacidad */
    background-color: rgba(215, 92, 15, 0.85);
    color: white;
    padding: 5px;
    font-size: 12px; /* Ajusta según lo necesites */
    display: flex;
    justify-content: space-between;
}

/* .card-buttons {
    position: absolute;
    right: 5px;
    top: 5px;
    display: none;
    flex-direction: column;
    gap: 5px;
}  */

.card-buttons {
    position: absolute;
    right: 5px;
    top: 5px;
    display: none;
    flex-direction: column;
    gap: 5px;
    z-index: 1; /* Asegurarse de que los botones estén por encima de otros elementos */
}

.card-item:hover .card-buttons {
    display: flex;
}

.add-btn {
    background-color: green;
    color: white;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.add-btn:focus {
    outline: none;
}

.remove-btn {
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.remove-btn:focus {
    outline: none;
}

.trade-btn {
    display: none;
    position: absolute;
    bottom: 15%; /* Ajustado para que esté más abajo en la carta */
    left: 82%;
    transform: translateX(-50%);
    background-color: orange;
    border: none;
    cursor: pointer;
    /*padding: 10px; /* Aumenta el padding para que el botón sea más grande */
    border-radius: 50%;
    transition: background-color 0.3s;
}

.card-item:hover .trade-btn {
    display: block;
}



.trade-btn.active-trade {
    background-color: rgb(124, 75, 0);
}

.trade-btn.active-trade:hover {
    background-color: rgb(196, 54, 3);
}

.search-btn {
    display: none;
    position: absolute;
    bottom: 15%; /* Ajustado para que esté más abajo en la carta */
    left: 82%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    /*margin-top: 5px; /* Ajusta según necesidad */
}

.search-btn:hover{
    background-color: rgb(79, 78, 78);
}

.card-item:hover .search-btn {
    display: block;
}
