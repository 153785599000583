.login-container {
    display: flex;
    height: 100vh;
    background-color: #3139B8; /* Color de fondo azul oscuro */
    font-family: 'AR One Sans', sans-serif;
}

.login-logo-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3139B8; /* Color de fondo inicial, será reemplazado por la animación */
    background-image: url('../../assets/images/tcgpoktrading.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120%;

    animation: backgroundAnimation 8s infinite alternate; /* 8 segundos por ciclo, repite indefinidamente, y va y viene */
}

@keyframes backgroundAnimation {
    0%   { background-color: #1e5182; }
    33%  { background-color: #3188e8; }
    67%  { background-color: #8b00f0; }
    100% { background-color: #e100fd; }
}

.login-form-section {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #192e3e;
    position: relative; /* Para posicionar el logo en la esquina */
    color: white;
}

.login-logo{

}

.login-logo-img {
    position: absolute; /* Posicionamos el logo en la esquina superior izquierda */
    top: 20px;
    left: 20px;
    width: 180px;
    height: auto;
}

.login-form {
    width: 100%; /* Cambiamos el ancho a 100% */
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login-form label {
    color: #4aafb3; /* Color azul para los textos */
    font-weight: bold;
}

.login-title {
    color: #4aafb3; /* Color azul para el título */
    margin-bottom: 20px; /* Espacio entre el título y el formulario */
    font-size: 42px; /* Tamaño de fuente para el título */
    font-weight: bold; /* Estilo en negrita para el título */
}

.login-form input {
    padding: 10px;
    border: 1px solid #4aafb3;
    border-radius: 5px;
    width: 100%; /* Aseguramos que los inputs ocupen todo el ancho disponible */
    box-sizing: border-box; /* Esto asegura que el padding y el borde estén incluidos en el ancho total */
}

.login-form button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #9002e8; /* Color azul para el botón */
    color: #FFFFFF;
    cursor: pointer;
    font-weight: bold;
}

.login-form button:hover{
    background-color: #e100fd;
}

.signup-text {
    color: #4aafb3; /* Color azul para el texto */
    margin-top: 20px; /* Espacio adicional antes del botón de registro */
}

.signup-section {
    position: absolute; /* Posicionamos esta sección en la parte inferior */
    bottom: 50px; /* Reducimos el espacio desde el fondo */
    width: 80%; /* Ancho de la sección */
    max-width: 400px; /* Ancho máximo */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px; /* Espacio entre el texto y el botón */
    margin-top: 40px; /* Agregamos margen superior */
}

.signup-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4aafb3; /* Color azul para el botón */
    color: #FFFFFF;
    cursor: pointer;
    font-weight: bold;
    width: 100%; /* Ancho completo de la sección */
    text-align: center; /* Centrar el texto del botón */
}

.signup-button:hover{
    background-color: #265a5c;
}

.login-form label {
    display: block; /* Hacer que las etiquetas ocupen toda la línea */
    margin-bottom: 5px; /* Espacio entre la etiqueta y el cuadro de texto */
    color: #4aafb3; /* Color azul para las etiquetas */
}

.forgot-password {
    margin-top: 10px; /* Espacio desde el botón "Iniciar Sesión" */
    color: #4aafb3; /* Color azul para el texto */
    cursor: pointer; /* Cambiar el cursor a mano al pasar el mouse */
    text-decoration: underline; /* Subrayar el texto para indicar que es un enlace */
    align-self: center;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.formulario-login{
    width: 80%;
}

/* Media Queries */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column; /* Cambia a una disposición de columna en dispositivos más pequeños */
        align-items: center; 
    }

    .login-form-section {
        flex: none;
        width: 100%; /* Ocupa todo el ancho en dispositivos móviles */
    }
    
    .login-logo-section {
        display: none;
    }
    
    .login-form {
        max-width: none; /* Permite que el formulario se expanda al ancho completo en dispositivos móviles */
    }
    
    .signup-section {
        order: 2; /* Asegúrate de que la sección de registro venga después del formulario de login */
        width: 90%; /* Ajusta el ancho según sea necesario */
        bottom: auto; /* Deshacer el posicionamiento absoluto si es necesario */
        position: relative; /* Cambia a posicionamiento relativo */
        margin-top: 20px; /* Asegura espacio encima de la sección de registro */
        padding-bottom: 133px;
    }

    .forgot-password {
        order: 2; /* Asegura que el enlace de contraseña olvidada se coloque justo después del formulario */
        margin-bottom: 20px; /* Proporciona un espacio debajo del enlace de contraseña olvidada */
    }

    .formulario-login{
        order: 1;
        width: 90%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .login-logo {
        margin-bottom: 19px;
    }
}

@media (max-width: 480px) {
    .login-form label,
    .login-title,
    .signup-text,
    .forgot-password {
        text-align: center; /* Centra el texto en dispositivos muy pequeños */
    }
    .login-logo {
        margin-bottom: 19px;
    }

    .login-logo-img {
        width: 150px; /* Reduce el tamaño del logo en pantallas muy pequeñas */
    }

    .login-form input,
    .login-form button,
    .signup-button {
        font-size: 16px; /* Aumenta el tamaño de fuente para facilitar la interacción táctil */
    }

    .login-logo-section{
        display: none;
    }

    .formulario-login{
        order: 1;
        width: 90%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}