/* CardPlaceholder.css */
.card-placeholder {
    width: 122px; /* Ajusta al tamaño de tus cartas */
    height: 170px;
    background-color: #15cc73;
    margin: 10px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .card-image-placeholder {
    height: 80%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    animation: skeleton-loading 1.5s infinite;
  }
  
  .card-info-placeholder {
    height: 20%;
    padding: 5px;
  }
  
  .card-number-placeholder,
  .card-name-placeholder {
    height: 10px;
    background-color: #ccc;
    margin: 3px 0;
  }
  
  @keyframes skeleton-loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  