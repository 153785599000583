.edition-slider {
    background-color: #192e3e;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 20px; /* Espacio entre los elementos del slider */
    justify-content: space-between;
    padding: 0px 10px
}

.edition-slider::-webkit-scrollbar {
    display: none; /* Ocultar la barra de desplazamiento en navegadores basados en WebKit */
}

.edition-item {
    display: inline-block;
    width: 80px; /* Puedes ajustar este valor según lo necesites */
    text-align: center;
}

.edition-item img {
    width: 100%;
    max-width: 100px; /* Ajusta este valor según el tamaño que desees para los logos */
    height: auto;
}

.edition-item img:hover {
    transform: scale(1.2);
}

/* Estilos para el modal */
.ReactModal__Content {
    width: 60%; /* Ajusta el ancho del modal */
    margin: 50px auto; /* Centra el modal vertical y horizontalmente */
    background-color: #fff; /* Color de fondo del modal */
    padding: 20px; /* Espaciado interno del modal */
    overflow-y: auto; /* Añade una barra de desplazamiento si el contenido es muy largo */
}

/* Estilos para el grid de sets y ediciones dentro del modal */
.modal-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Crea 2 columnas de igual ancho */
    gap: 20px; /* Espaciado entre los elementos del grid */
    padding-top: 15px;
}

.modal-grid img {
    width: 300px; /* Ajusta este valor según el tamaño que desees para los logos dentro del modal */
    height: auto;
    display: block;
    margin: 0 auto; /* Centra la imagen horizontalmente */
}

.modal-button {
    border: 2px solid black; /* Borde negro */
    padding: 10px; /* Espaciado interno */
    display: flex; /* Hace que el contenido del div se centre */
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
    cursor: pointer; /* Cambia el cursor a mano al pasar el mouse */
    transition: background-color 0.3s; /* Transición suave al cambiar el color de fondo */
}

.modal-button:hover {
    background-color: #9e9c9c; /* Cambia el color de fondo al pasar el mouse */
}


/*Estilos para el boton cerrar*/
.close-button {
    position: absolute;
    top: -5px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px; /* Tamaño del ícono */
    cursor: pointer;
    color: #000; /* Color del ícono */
}

.close-button:hover {
    color: #880cbd; /* Cambia el color al pasar el mouse */
}

/* Estilos para el botón "Volver atrás" */
.back-button {
    display: block;
    margin: 20px auto; /* Centra el botón horizontalmente */
    padding: 10px 20px;
    background-color: #880cbd;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s; /* Transición suave al cambiar el color de fondo */
}

.back-button:hover {
    background-color: #6a0098; /* Oscurece el color al pasar el mouse */
}

.vermas-button{
    background-color: #880cbd;
    color: #fff;
    border: none;
    cursor: pointer;  
    transition: background-color 0.3s; 
    margin: 1px 1px 1px;
}

.vermas-button:hover{
    background-color: #6a0098; /* Oscurece el color al pasar el mouse */
}