.exchange-list-section {
    margin-top: 30px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.exchange-table {
    width: 100%;
    border-collapse: collapse;
}

.exchange-table th, .exchange-table td {
    border: 1px solid #e0e0e0;
    padding: 5px;
    /*text-align: left;*/
}

.table-card-image {
    width: 60px;
    object-fit: cover;
}

.table-edition-logo {
    width: 30px;
    margin-right: 5px;
    vertical-align: middle;
}

.filters-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filters-container select, .filters-container input {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
}

.pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination button {
    padding: 5px 10px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    transition: background-color 0.2s;
}

.pagination button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.pagination span {
    font-weight: bold;
}

.boton-ver-exchangetable{
    background-color: blueviolet;
    color: white;
    width: 100px;
}

.boton-loquiero-exchangetable{
    background-color: #0056b3;
    color: white;
    width: 100px;
}

/*CSS PARA GRID */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Ajusta según tus necesidades */
    gap: 16px; /* Espacio entre los elementos de la cuadrícula */
}

.grid-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: row; /* Cambia a column si prefieres que sea vertical */
    overflow: hidden;
    padding-left: 3%;
}

.card-image-container {
    flex: 0 0 100px; /* Ajusta el ancho según tus necesidades */
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-card-image {
    max-width: 100%;
    height: auto;
}

.card-info-container {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-edition {
    display: flex;
    align-items: center;
    font-size: 0.8em; /* Ajusta según tus necesidades */
}

.grid-edition-logo {
    max-width: 25px; /* Ajusta según tus necesidades */
    margin-right: 8px;
}

.card-name {
    font-size: 1.3em; /* Ajusta según tus necesidades */
    margin: auto;
    padding: 5%;
}

.card-number {
    font-size: 0.8em; /* Ajusta según tus necesidades */
}

.user-info {
    font-size: 0.8em; /* Ajusta según tus necesidades */
}

.username,.region{
    display: block;
}

.date-info {
    font-size: 0.6em; /* Ajusta según tus necesidades */
    align-self: flex-end;
}

.button-view {
    margin-top: auto;
    padding: 8px;
    cursor: pointer;
    background-color: blueviolet;
    color: white;
}

.search-container {
    position: relative;
    /* Resto de tus estilos para el contenedor de búsqueda */
  }
  
  .search-input-exchange {
    /* Estilos para tu input de búsqueda */
    padding-right: 30px; /* Asegúrate de dejar espacio para el ícono de 'x' */
  }
  
  .clear-search-button {
    position: absolute;
    right: 10px; /* O el espacio que necesites */
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    cursor: pointer;
  }
  
  .clear-search-button i {
    color: #ccc; /* O el color que prefieras */
  }
  