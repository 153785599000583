.forgot-password-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
}

.forgot-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.forgot-password-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.forgot-password-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.forgot-password-container button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.forgot-password-container button:hover {
    background-color: #0056b3;
}

.forgot-password-container p {
    text-align: center;
    color: #666;
}
