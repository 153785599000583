.saga-selector {
    background-color: #192e3e;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px; /* Espacio entre los logos */
    padding: 5px;
}

.saga-selector img {
    cursor: pointer;
    transition: transform 0.3s ease;

    /* Puedes ajustar el tamaño según lo necesites */
    width: 100px;
    height: auto;
}

.saga-selector img:hover {
    transform: scale(1.1); /* Efecto de zoom al pasar el mouse */
}


.saga-item {
    cursor: pointer;
    text-align: center;
}

.saga-item img {
    width: 60px; /* Ajusta según el tamaño de tus logos */
    height: auto;
}

.active-saga {
    border: 2px solid #3139B8; /* O cualquier otro estilo que desees para resaltar la saga activa */
    padding: 8px; /* Añade un poco de espacio alrededor del logo */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Una sombra suave para destacarla */
}
