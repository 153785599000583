/* Definir el fondo azul para el cuerpo del documento */
body {
    background-color: #192e3e !important; /* Color azul */
}

.register-container {
    min-height: 100vh; /* Asegurarse de que el contenedor principal ocupe al menos toda la altura de la ventana */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Añadir un padding al contenedor principal */
    background-color: transparent; /* Asegurarse de que el contenedor principal no tenga un fondo */
}


.register-step2 {
    padding: 20px; /* Añadir un padding al contenedor del paso 2 */
    width: 100%; /* Asegurarse de que el contenedor del paso 2 ocupe todo el ancho disponible */
    box-sizing: border-box; /* Asegurarse de que el padding y el borde estén incluidos en el ancho total */
}

.register-step1, .register-step2 {
    width: 80%;
    max-width: 480px;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
}

.input-group {
    margin-bottom: 20px;
}

.input-group label {
    display: block;
    margin-bottom: 10px;
    color: #3139B8;
}

label {
    display: block;
    color: #3139B8;
}

.error-message {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
}


.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #3139B8;
    border-radius: 5px;
}

.register1-button {
    background-color: #3139B8; /* Color azul */
    color: #FFFFFF; /* Texto blanco */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.register1-button:hover {
    background-color: #2a2f9e; /* Un tono de azul un poco más oscuro para el efecto hover */
}

.register-title {
    color: #3139B8; /* Color azul */
    font-size: 24px; /* Aumenta el tamaño según lo que necesites */
    text-align: center; /* Centra el texto */
    margin-bottom: 20px; /* Espacio debajo del título */
}

/* RegisterStep2.css */
.register-step2-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.register-title {
    color: #3139B8;
    font-size: 24px;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

input, select, button {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.register-button {
    background-color: #3139B8;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.register-button:hover {
    background-color: #2a2f9e;
}

.register-step1 .input-group {
    margin-bottom: 15px; /* Añadir un margen inferior a cada grupo de entrada */
}

.contact-methods {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.contact-methods-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.input-group select {
    width: 100%;
}

.register-step1 {
    padding: 20px; /* Añadir un padding al contenedor */
}

.register-step1 .input-group input {
    width: 100%; /* Establecer un ancho del 100% para los campos de entrada */
    box-sizing: border-box; /* Asegurarse de que el padding y el borde estén incluidos en el ancho total */
    padding: 10px; /* Añadir un poco de padding para hacer los campos de entrada más grandes */
    border: 1px solid #ccc; /* Añadir un borde para que los campos de entrada sean visibles */
    border-radius: 4px; /* Añadir bordes redondeados para un mejor aspecto */
}