.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.reset-password-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.reset-password-form {
    width: 300px;
    display: flex;
    flex-direction: column;
}

.reset-password-form label {
    margin-bottom: 10px;
}

.reset-password-form input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.error-message {
    color: red;
    text-align: center;
}
