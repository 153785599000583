.exchange-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .exchange-modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%; /* Puedes ajustar el ancho según tus necesidades */
  max-width: 960px; /* Esto asegura que el modal no sea demasiado ancho en pantallas grandes */
  overflow-y: auto; /* Permite desplazarse si el contenido es demasiado largo */

  }
  
  .close-button-exchange-modal {
    float: right;
    border: none;
    background: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    margin-left: auto; /* Alinea el botón a la derecha */
    display: block; /* Hace que el botón no esté alineado con el texto */  
    position: absolute;
    top: -5px;
    right: 10px;
    font-size: 24px; /* Tamaño del ícono */
  }
  
  .exchange-modal-content {
    display: block;
    align-items: center;
    gap: 20px;
  }
  
  .exchange-modal-image {
    max-width: 150px;
    /* Asegúrate de ajustar esto según tus necesidades */
  }
  
  .exchange-modal-details {
    flex-grow: 1;
  }
  
  .exchange-modal-actions {
    margin-top: 20px;
  }
  
  .exchange-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .exchange-button:hover {
    background-color: #0056b3;
  }
  
  .exchange-modal-body {
    display: flex;
  }
  
  .exchange-modal-content-left {
    flex: 1;
    justify-content: center;
    padding: 20px;
  text-align: center;
  flex-direction: column;
  }

 
  
  .exchange-modal-card-image {
    /* Ajusta el tamaño según sea necesario */
    max-height: 300px;
  }
  
  .exchange-modal-content-right {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 50%;
  }
  
  .exchange-modal-card-details {
    /* Ajusta el tamaño según sea necesario */
  }
  
  .exchange-modal-edition-logo {
    /* Tamaño pequeño para el logo de la edición */
    max-width: 20%;
  }
  
  .exchange-modal-wanted-cards {
    /* Estilos para el slider de cartas buscadas */
    height: auto;
    width: auto;
  }
  
  .exchange-modal-offer-summary {
    /* Estilos para el resumen de la oferta */
  }
  
  .exchange-modal-actions {
    /* Estilos para la sección de acciones */
  }

  .swiper-container {
    width: 100%;
    height: 200px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    border: 1px solid #ccc; /* Esto agregará un borde ligero a cada diapositiva */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
    /* Centrar imagen verticalmente y horizontalmente */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: auto; /* Asegúrate de que la imagen no se deforme */
    max-height: 100px; /* Ajusta el tamaño según tus necesidades */
    margin: 0 auto;
  }
  
  /* Estilos para el resumen de la oferta y el input de complemento con dinero */
  .exchange-modal-offer-summary {
    /*margin-top: 20px;*/
  }
  
  .exchange-modal-offer-summary h3 {
    margin-bottom: 10px;
  }
  
  .exchange-modal-offer-summary div {
    margin-bottom: 5px;
  }
  
  /* Estilo del checkbox */
  .exchange-modal-offer-summary label {
    display: block;
    margin-top: 10px;
  }
  
  .exchange-modal-offer-summary input[type="checkbox"] {
    margin-right: 5px;
  }
  
  /* Estilo del input para el dinero */
  .exchange-modal-offer-summary input[type="text"] {
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    
  }
  
  .swiper-button-prev,
.swiper-button-next {
  color: #007bff;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px; /* La mitad de la altura para centrar verticalmente */
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.exchange-modal .swiper-slide {
    flex-shrink: 1;
}

.boton-ofrecer-modal{
    background-color: purple;
    color: white;
}

.card-image-bw{
  transform: scale(1.1);
  filter: grayscale(90%);
}

.card-highlight {
  position: relative;
  border: 2px solid green; /* o el estilo que prefieras */
}

.card-highlight::after {
  content: "Lo busca";
  position: absolute;
  top: 90px;
  left: 5px;
  background: green;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

.nombre-carta-modal-ex{
  font-size: 15px;
}


.edition-image{
  width: auto;
  height: 40px;
}

.offered-cards-list {
  max-height: 150px; /* Puedes ajustar esto según sea necesario */
  overflow-y: auto; /* Esto añadirá un desplazamiento si el contenido excede la altura máxima */
  margin-bottom: 10px; /* Añade un poco de espacio si lo necesitas */
}

.offered-cards-list::-webkit-scrollbar {
  width: 12px; /* o cualquier tamaño que desees */
}

.offered-cards-list::-webkit-scrollbar-thumb {
  background: #888; /* o cualquier color que desees */
  border-radius: 6px; /* para bordes redondeados */
}

.offered-cards-list::-webkit-scrollbar-thumb:hover {
  background: #555; /* o cualquier color que desees para el hover */
}

.offered-cards-list {
  scrollbar-width: thin; /* para Firefox */
  scrollbar-color: #888 #ffffff; /* para Firefox, barra y fondo respectivamente */
}

.boton-quitar {
  background-color: red;
  color: white;
  width: 75px;
  /* Añade aquí más estilos si es necesario */
}

.swiper-pagination {
  display: none;
}