body {
    background: #01497a !important; /* Color de fondo claro */
}

/* Estilos generales */
.home-container {
    font-family: 'Arial', sans-serif;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
   /* padding: 20px; */
   position: relative;
}

/* Estilos para el header */
.home-header {
    background-color: #0f3a5b; /* Color de fondo oscuro */
    color: #fff; /* Color de texto blanco para contraste */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    position: relative;
   /* border-bottom: 2px solid #ddd; */
}

.home-logo {
    width: 170px; /* Ajusta según el tamaño deseado para el logo */
}

.home-menu {
    display: flex;
    gap: 20px;
}

.home-menu a {
    text-decoration: none;
    color: #fff; /* Color de texto blanco para contraste */
    padding: 5px 10px;
    transition: background-color 0.3s;
}

.home-menu a:hover {
    background-color: #3139B8;
}


.home-menu a.active{
    background-color: rgb(17, 103, 103); /* Un color ligeramente más oscuro que el fondo del header */
    border-radius: 5px; /* Para darle un aspecto de botón */
    padding: 8px 8px 8px;
}
.account-button {
    background-color: #ff417c; /* Color de fondo blanco */
    color: white; 
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.account-button:hover {
    background-color: #831f3f; /* Color de fondo más claro al pasar el mouse */
}

.account-menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ff417c;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    z-index: 1000;
}

.account-menu-dropdown button {
    /*position: absolute;*/
    top: 100%;
    right: 0;
    display: block;
    width: 100%;
    padding: 10px 15px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
}

.account-menu-dropdown button:hover {
    background-color: #f5f5f5;
}

/* Estilos para el body */
.home-body {
    /*margin-top: 5px;*/
    background-color: #0f3a5b;
}

.sagas-logos {
    /* display: flex; */
    justify-content: center;
    gap: 20px;
   /* margin-bottom: 20px; */
}

/* Estilos para el slider de sets */
.sets-slider {
    /* Aquí puedes agregar estilos para el slider */
}

/* Estilos para el bloque de cartas */
.cards-block {
    width: 100%; /* Ocupa todo el ancho del body de Home */
    text-align: center; /* Centra el contenido horizontalmente */
    padding: 20px 0; /* Agrega un poco de espacio arriba y abajo para que se vea bien */
}


.cards-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

/* Estilos para las cartas */
.card {
    width: 100px; /* Ajusta según el tamaño deseado para las cartas */
    position: relative;
}

.card:hover .card-actions {
    display: block;
}

.card-actions {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
}

.card-actions button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
    transition: background-color 0.3s;
}

.card-actions button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.home-container .saga-selector {
   /* margin-top: 20px; */
}

.filter-buttons {
    margin: 10px 0;
}

.filter-buttons button {
    padding: 10px 15px;
    margin-right: 10px;
    border: none;
    background-color: rgb(17, 103, 103);
    cursor: pointer;
    transition: background-color 0.3s;
    color: white;
}

.filter-buttons button.active {
    background-color: #192e3e;
    color: white;
    border: solid 1px #2aeffc;
}

.edition-title {
    background-color: #192e3e; 
    color: white; /* Letras blancas */
   /* display: inline-block; /* Para que tome el ancho del contenido */
    padding: 10px 0px; /* Espaciado interno para que se vea bien */
    border-radius: 10px; /* Esquinas redondeadas */
    margin: 0 auto 20px auto; /* Centra el bloque en el contenedor y agrega espacio debajo */
    width: 100%;
    display: flex; /* Cambiamos a flex para alinear el logo y el texto horizontalmente */
    align-items: center; /* Alinea verticalmente el logo y el texto */
    justify-content: center;
   /* justify-content: space-between;  Cambia a space-between para separar el título y el buscador */

}

.edition-logo {
    width: 70px; /* Ajusta según el tamaño deseado para el logo */
    height: auto; /* Mantiene la proporción del logo */
    margin-right: 10px; /* Espacio entre el logo y el texto */
}

/* ESTILOS BUSCADOR*/
.search-results-table {
    width: 100%;
    border-collapse: collapse;
}

.search-results-table th, .search-results-table td {
    border: 1px solid #ccc;
    padding: 8px 12px;
    text-align: center;
}

.search-results-table th {
    background-color: #f5f5f5;
}

/* .search-container {
    background-color: #0f3a5b; 
    display: flex; / Usamos flexbox para centrar los elementos /
    justify-content: center; /* Centra los elementos horizontalmente /
    align-items: center; /* Centra los elementos verticalmente /
    padding: 10px 0; /* Agrega un poco de espacio arriba y abajo /
} */

.search-container-home {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 3px 20px;
    background-color: #fff; /* Añade un fondo blanco para que el buscador resalte sobre el banner */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 60%;
    box-shadow: 0px 3px 23px 11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 23px 11px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 23px 11px rgba(0,0,0,0.75);;
    bottom: 5%; /* Ajusta este valor para que el buscador se superponga al banner como desees */
}

.search-container-home input[type="text"] {
    width: 70%; /* Hace que el campo del buscador sea más ancho */
    margin-right: 10px; /* Agrega un espacio entre el campo de texto y el botón */
}

.edition-search-input {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-left: 10px;
}

.search-container-home-button{
    background-color: #439aec !important;
    color: white;
    width: 20%;
    border-radius: 15px;
}

.search-container-home-button:hover{
    background-color: #0f3a5b !important;
    color: white;
    width: 20%;
}

.search-container-home-input{
    border: none;
}

.wizard-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.wizard-modal > div {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
}

/* Capa oscura */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.98);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* Esto hace que la capa sea "intocable" */
}

/* Texto en la capa oscura */
.overlay p {
    color: white;
    font-size: 24px;
    pointer-events: auto; /* Esto permite que el texto sea "tocable" */
}

/* Área destacada de sagas */
.sagas-logos {
    position: relative;
   /* z-index: 1001; */
}

/* Área destacada para ediciones */
.sets-slider {
    position: relative;
   /* z-index: 1001;*/
}

.highlighted {
    z-index: 1001;
}

.saga-logo.active {
    /* Estilos para destacar la saga activa. Puede ser un borde, un fondo diferente, etc. */
    border: 2px solid blue;
}

.main-banner {
    width: 100%; /* Ocupa todo el ancho disponible */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para darle profundidad */
    border-radius: 8px; /* Bordes redondeados */
    overflow: hidden; /* Oculta cualquier contenido que se salga de los bordes redondeados */
    position:relative;
    padding-bottom: 5px;
    max-width: 1200px;
}

.main-banner img {
    width: 100%; /* Hace que la imagen ocupe todo el ancho del banner */
    height: auto; /* Mantiene la proporción de la imagen */
    display: block; /* Evita que la imagen tenga espacio extra debajo */
}