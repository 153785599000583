body {
    /*background: rgba(50, 56, 186, 0.68) !important;  */
    background: #023566 !important;
}

/* Estilos generales */
.exchange-container {
    font-family: 'Arial', sans-serif;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

/* Estilos para el header */
.exchange-header {
    background-color: #0f3a5b; /* Color de fondo oscuro */
    color: #fff; /* Color de texto blanco para contraste */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    position: relative;
}

.exchange-logo {
    width: 170px; /* Ajusta según el tamaño deseado para el logo */
}

.exchange-menu {
    display: flex;
    gap: 20px;
}

.exchange-menu a {
    text-decoration: none;
    color: #fff; /* Color de texto blanco para contraste */
    padding: 5px 10px;
    transition: background-color 0.3s;
}

.exchange-menu a:hover {
    background-color: #3139B8;
}

.exchange-menu a.active {
    background-color: rgb(17, 103, 103); /* Un color ligeramente más oscuro que el fondo del header */
    border-radius: 5px; /* Para darle un aspecto de botón */
    padding: 8px 8px 8px;
}

/* Área destacada de sagas */
.sagas-logos {
    justify-content: center;
    gap: 20px;
}

/* Estilos para la sección de cartas más buscadas */
.most-searched-section {
    margin-top: 30px; /* Espacio desde la sección superior */
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 20px;
    background-color: #192e3e; /* Fondo blanco para resaltar las cartas */
    border-radius: 10px; /* Bordes redondeados para un aspecto más suave */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave para añadir profundidad */
    color: white;
}

.most-searched-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Grid adaptativo */
    gap: 20px; /* Espacio entre cartas */
    justify-items: center; /* Centrar las cartas en sus respectivas celdas */
}

.card {
    width: 100px; /* Ancho de la carta */
    padding: 10px; /* Espacio interno */
    background-color: #0f3a5b; /* Color de fondo claro */
    border-radius: 10px; /* Bordes redondeados */
    text-align: center; /* Alinear el texto al centro */
    transition: transform 0.3s, box-shadow 0.3s; /* Efectos de transición suaves */
}

.card:hover {
    transform: translateY(-10px); /* Efecto de levitación al pasar el cursor */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Sombra al pasar el cursor */
}

.card img {
    width: 80px; /* Ancho de la imagen */
    object-fit: cover; /* Ajustar la imagen proporcionalmente */
    /* margin-bottom: 10px;  Espacio entre la imagen y el nombre de la carta */
}

.td-boton-tabla{
    width: 110px;
}

.exchange-edition-logo {
    width: 40px; /* Ajusta según el tamaño deseado para el logo de edición */
    margin-top: 5px;
}

.active-saga {
    border: 2px solid #3139B8; /* Esto es solo un ejemplo, puedes usar un borde, un fondo diferente, etc. */
    padding: 8px; /* Añade un poco de espacio alrededor del logo o del nombre de la saga */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Una sombra suave para destacarla */
    background-color: rgb(17, 103, 103);
}

